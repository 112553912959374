import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import Link from 'components/Link';
import cx from 'classnames';
import { Container, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import * as R from 'ramda';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import logoWhite from 'assets/logoWhite.png';
import logoBlack from 'assets/logoBlack.png';

// local
import styles from './Menu.module.scss';

const LINKS = [
  { to: '/auth', name: 'Auth', title: 'Go to Auth Playground' },
  { to: '/user/lookup', name: 'Users', title: 'Go to User Lookup' },
  { to: '/graphql', name: 'GraphQL', title: 'Go to GraphQL Playground' },
  { to: '/bets', name: 'Portfolio', title: 'Go to Portfolio' },
  { to: '/me', name: 'Profile', title: 'Go to Your Profile', alternates: ['/team'] },
  { to: '/logout', name: 'Logout', title: 'Logout' },
];

export default applyTo(({
  onClose,
  className,
  // user,
  isLightTheme,
}) => {
  const { pathname } = useLocation();

  return (
    <Container className={ cx(styles.wrapper, className) }>
      <IconButton onClick={ onClose } className={ styles.close }>
        <CloseIcon />
      </IconButton>
      <nav className={ styles.mobileMenu }>
        <Link
          to={ '/' }
          onClick={ onClose }
          title={ 'Go Home' }
          style={{ marginBottom: '30px' }} >
          <img
            src={ isLightTheme ? logoBlack : logoWhite }
            alt="logo"
            height='100px' />
        </Link>
        
        {
          LINKS.map(({ to, name, title, alternates = [] }) => {
            const isActive = (to === '/' || to === '')
              ? R.any(to => R.equals(to, pathname), [to, ...alternates])
              : R.any(to => R.startsWith(to, pathname), [to, ...alternates]);
              
            const classNames = cx(styles.link, {
              [styles.active]: isActive,
            });

            return (
              <Link
                to={ to }
                key={ to }
                disabled={ isActive }
                title={ (isActive ? null : title) }
                className={ classNames }
                onClick={ onClose }>{ name }</Link>
            );
          })
        }
        { /* <Link to='/pipeline' onClick={ onClose }>Pipeline</Link> */ }
        { /* <Link to='/user' onClick={ onClose }>Users</Link> */ }
        { /* <Link to='/team' onClick={ onClose }>Team Members</Link> */ }
      </nav>
    </Container>
  );
}, pipe(
  propTypes({
    className: PropTypes.string,
    onClose: PropTypes.func,
    // user: PropTypes.shape({
    //   email: PropTypes.string,
    // }),
    isLightTheme: PropTypes.bool,
  }),
  defaultProps({
    className: null,
    onClose: () => {},
    // user: {
    //   id: '',
    //   email: '',
    // },
    isLightTheme: false,
  }),
  memo,
));
