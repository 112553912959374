/* eslint-disable max-statements, react/no-multi-comp */
import React, { memo, useState, useCallback, useEffect } from 'react';
import * as R from 'ramda';
// import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Typography, Button, Icon, Card, Chip } from '@material-ui/core';
import { differenceInMinutes, formatDistanceStrict } from 'date-fns/fp';
import { props } from '@vl/js-lib/browser/async';
import { serialize as serializeQs } from '@vl/js-lib/browser/url/querystring';

// aliased
import config from 'conf';
import Link from 'components/Link';
import NestedData from 'components/NestedData';
import FlexCenter from 'components/FlexCenter';
import { useNikeAuth } from 'components/NikeAuthProvider';
import CopyToClipboard from 'components/CopyToClipboard';
import { propTypes, defaultProps } from 'lib/react';
import { useInterval } from 'lib/hooks/useInterval';
import { useForceUpdate } from 'lib/hooks/useForceUpdate';
import { parseJwt, jwtExpiresAt, jwtIssuedAt } from 'lib/auth';

// local
import Token from '../Token';
import styles from '../AuthView.module.scss';

const POLL_INTERVAL = 5; // seconds

const JTL_CONSOLE_URL = 'https://console.l2w-test.nikecloud.com';

const CONSUMER_CLIENT_MAP = {
  b2a46dd1cffab5934255c6c02747d944: 'Betazone',
  '64d30fa046ce72585b19adfb22c4ecd4': 'Betazone',
  
  d6db61fa53325f194ba7923ace13a6a5: 'Betazone iOS', // test
  '4360de6237b0cd3bc5ef0e255cd5e688': 'Betazone iOS', // prod
};

const NIKE_ISSUER_MAP = {
  'https://accounts.nikedev.com': 'Test',
  'https://accounts.nike.com': 'Prod',
};


// https://developer.niketech.com/docs/projects/IdnAccount?tab=api
// Fetch -> String -> String
const getIdnUser = R.curry((fetch, accessToken, upmId) => {
  return fetch(`${ config.NIKE_API_HOST }/identity/user/v1/${ upmId }/read`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-nike-ux-id': 'com.nike.vl.console',
      authorization: `Bearer ${ accessToken }`,
    },
    body: JSON.stringify({
      fields: [
        // 'upmId',
        'nuId',
        'language', // en
        'location', // { country: 'US' }
        'gender', // M
        'avatar', // { small, medium, large }
      ],
    }),
  }).then(res => res.json());
});


// Fetch -> String -> String
const getJtlUser = R.curry((fetch, accessToken) => {
  return props({
    user: fetch(`${ config.JTL_API_HOST }/v1/users/current`, {
      headers: { authorization: `Bearer ${ accessToken }` },
    }).then(res => res.json()),
    participations: fetch(`${ config.JTL_API_HOST }/v1/users/current/participations`, {
      headers: { authorization: `Bearer ${ accessToken }` },
    }).then(res => res.json()),
  })
    .then(({ user, participations }) => ({
      ...user,
      participations: R.propOr([], 'participations', participations),
    }));
});

const nikeUserName = R.pipe(
  R.juxt([
    R.path(['profile', 'given_name']),
    R.path(['profile', 'family_name']),
  ]),
  R.reject(R.isNil),
  R.join(' '),
);

// User -> URL
const userLookupLink = user => {
  const fields = ['nuId', 'email', 'upmId', 'athleteId'];
  const field = R.find(field => user[field], fields);
  if (!field) return;
  const qs = serializeQs({ field, value: user[field] });
  return `/user/lookup?${ qs }`;
};

// eslint-disable-next-line max-statements, complexity
export default R.applyTo(({ onCopy }) => {

  const [idnUserFields, setIdnUserFields] = useState(null);
  const [jtlUserFields, setJtlUserFields] = useState(null);
  const [accessTokenTab, setAccessTokenTab] = useState(0);
  const [idTokenTab, setIdTokenTab] = useState(0);
  useInterval(useForceUpdate(), POLL_INTERVAL * 1000);

  const {
    needsSignIn,
    signIn,
    signOut,
    user: nikeUser,
  } = useNikeAuth();
  
  const now = new Date();
  const accessToken = R.path(['access_token'], nikeUser);
  const idToken = R.path(['id_token'], nikeUser);
  const email = R.path(['profile', 'email'], nikeUser);
  const name = nikeUserName(nikeUser);

  const upmId = R.path(['upmId'], nikeUser);
  const nuId = R.path(['nuId'], idnUserFields || {});
  const athleteId = R.path(['athleteId'], jtlUserFields || {});
  const idTokenJson = R.unless(R.isNil, parseJwt)(idToken);
  const accessTokenJson = R.unless(R.isNil, parseJwt)(accessToken);
  const issuer = R.path(['iss'], idTokenJson);
  const env = R.propOr('', issuer, NIKE_ISSUER_MAP);

  const expiresAt = jwtExpiresAt(accessTokenJson) || new Date();
  const issuedAt = jwtIssuedAt(accessTokenJson) || new Date();

  const lookupLink = userLookupLink({ nuId, upmId, email, athleteId });
  const client = R.applyTo(accessTokenJson, R.pipe(
    R.path(['sub']),
    sub => `${ R.propOr('', sub, CONSUMER_CLIENT_MAP) } ${ (sub ? `(${ sub })` : '') }`,
  ));
  
  useEffect(() => {
    if (!upmId) {
      setIdnUserFields(null);
      setJtlUserFields(null);
      return;
    }

    props({
      jtlUser: getJtlUser(fetch, accessToken)
        .then(setJtlUserFields)
        .catch(console.error),
      idnUser: getIdnUser(fetch, accessToken, upmId)
        .then(setIdnUserFields)
        .catch(console.error),
    });
  }, [upmId]);

  const handleAccessTokenTabChange = useCallback((event, tab) => {
    setAccessTokenTab(tab);
  }, [setAccessTokenTab]);
  const handleIdTokenTabChange = useCallback((event, tab) => {
    setIdTokenTab(tab);
  }, [setIdTokenTab]);

  const handleLogin = useCallback(() => signIn(), [signIn]);
  const handleLogout = useCallback(() => signOut(), [signOut]);

  if (needsSignIn || !nikeUser) {
    return (
      <FlexCenter style={{ minHeight: '300px' }}>
        <Button
          variant={ 'contained' }
          color={ 'secondary' }
          size={ 'large' }
          title={ `Login or Signup to ${ new URL(config.OIDC_ISSUER).host }` }
          style={{ fontSize: '24px' }}
          onClick={ handleLogin }>Login or Signup</Button>
      </FlexCenter>
    );
  }

  return (
    <Grid container={ true } spacing={ 2 }>
      <Grid item={ true } xs={ 12 }>
        <Card>
          <Grid container={ true }>
            <Grid item={ true } xs={ 7 }>
              <NestedData
                style={{ fontSize: '18px', width: '100%' }}
                data={{
                  User: R.applyTo({
                    Name: name,
                    Email: email,
                    'UPM ID': upmId,
                    'NU ID': nuId,
                    'Athlete ID': athleteId,
                  }, R.pipe(
                    R.filter(R.identity),
                    R.evolve({
                      Name: name => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Link to={ lookupLink } title={ `Lookup ${ name }` }>
                            { name }
                            <Icon style={{ verticalAlign: 'middle', marginLeft: '10px' }}>{ 'search' }</Icon>
                          </Link>
                          <CopyToClipboard
                            disabled={ false }
                            label={ <Icon>{ 'content_copy' }</Icon> }
                            text={ name }
                            style={{ marginLeft: '5px' }}
                            className={ 'toolbar-button' }
                            title={ 'Copy Name' }
                            onCopy={ onCopy } />
                        </div>
                      ),
                      Email: email => (
                        <div>
                          <Link to={ `mailto:${ email }` }>{ email }</Link>
                          <CopyToClipboard
                            disabled={ false }
                            label={ <Icon>{ 'content_copy' }</Icon> }
                            text={ email }
                            className={ 'toolbar-button' }
                            title={ 'Copy Email' }
                            onCopy={ onCopy } />
                        </div>
                      ),
                      'UPM ID': upmId => (
                        <div>
                          { upmId }
                          <CopyToClipboard
                            disabled={ false }
                            label={ <Icon>{ 'content_copy' }</Icon> }
                            text={ upmId }
                            className={ 'toolbar-button' }
                            title={ 'Copy UPMID' }
                            onCopy={ onCopy } />
                        </div>
                      ),
                      'NU ID': nuId => (
                        <div>
                          { nuId }
                          <CopyToClipboard
                            disabled={ false }
                            label={ <Icon>{ 'content_copy' }</Icon> }
                            text={ nuId }
                            className={ 'toolbar-button' }
                            title={ 'Copy NUID' }
                            onCopy={ onCopy } />
                        </div>
                      ),
                      'Athlete ID': athleteId => (
                        <div>
                          { athleteId }
                          <CopyToClipboard
                            disabled={ false }
                            label={ <Icon>{ 'content_copy' }</Icon> }
                            text={ athleteId }
                            className={ 'toolbar-button' }
                            title={ 'Copy Athlete ID' }
                            onCopy={ onCopy } />
                        </div>
                      ),
                    }),
                  )),
                  TTL: {
                    Issued: (
                      <span title={ issuedAt.toISOString() }>
                        { `${ formatDistanceStrict(issuedAt, new Date()) } ago` }
                      </span>
                    ),
                    [(now > expiresAt) ? 'Expired' : 'Expires']: (
                      <div
                        style={{
                          color: differenceInMinutes(new Date(), expiresAt) <= 5
                            ? 'red'
                            : 'inherit',
                        }}>
                        <span title={ expiresAt.toISOString() }>
                          {
                            (now > expiresAt)
                              ? `${ formatDistanceStrict(new Date(), expiresAt) } ago`
                              : formatDistanceStrict(expiresAt, new Date())
                          }
                        </span>
                      </div>
                    ),
                  },
                  Client: client,
                  Environment: `${ env } ${ (issuer ? `(${ R.replace('https://', '', issuer) })` : '') }`,
                  Scopes: (
                    <div>
                      {
                        R.applyTo(accessTokenJson, R.pipe(
                          R.evolve({ lrscp: R.split(' ') }),
                          ({ scp, lrscp }) => [...scp, ...lrscp],
                          R.uniq,
                          R.sortBy(R.identity),
                          R.map(scope => (
                            <Chip
                              className={ styles.chip }
                              variant={ 'outlined' }
                              key={ scope }
                              label={ scope } />
                          )),
                        ))
                      }
                    </div>
                  ),
                }} />
            </Grid>
            <Grid
              item={ true }
              xs={ 5 }
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <Button
                    onClick={ handleLogin }
                    variant={ 'outlined' }
                    size={ 'large' }
                    title={ 'Refresh Consumer Tokens' }
                    startIcon={ <Icon>{ 'refresh' }</Icon> }
                    style={{ margin: '5px', width: '150px' }}
                  >
                    { ' Refresh' }
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={ handleLogout }
                    variant={ 'outlined' }
                    title={ 'Logout from accounts.nike.com' }
                    startIcon={ <Icon>{ 'logout' }</Icon> }
                    style={{ margin: '5px', width: '150px' }}
                  >
                    { ' Logout' }
                  </Button>
                </div>
              </div>
            </Grid>

          </Grid>
        </Card>
          
      </Grid>

      {
        accessToken && (
          <Grid item={ true } xs={ 6 }>
            <Card>
              <Typography variant='h4' align={ 'center' } style={{ background: 'rgba(0, 0, 0, 0.1)', padding: '10px' }}>
                <Icon style={{ marginRight: '10px', verticalAlign: 'middle' }}>{ 'lock' }</Icon>
                <span style={{ verticalAlign: 'middle' }}>{ 'Access Token' }</span>
              </Typography>
              <div>
                <Token
                  token={ accessToken }
                  onTabChange={ handleAccessTokenTabChange }
                  tab={ accessTokenTab } />
              </div>
            </Card>
          </Grid>
        )
      }
      {
        idToken && (
          <Grid item={ true } xs={ 6 }>
            <Card>
              <Typography variant='h4' align={ 'center' }  style={{ background: 'rgba(0, 0, 0, 0.1)', padding: '10px' }}>
                <Icon style={{ marginRight: '10px', verticalAlign: 'middle' }}>{ 'person' }</Icon>
                <span style={{ verticalAlign: 'middle' }}>{ 'Identity Token' }</span>
              </Typography>
              <div>
                <Token
                  token={ idToken }
                  onTabChange={ handleIdTokenTabChange }
                  tab={ idTokenTab } />
              </div>
            </Card>
          </Grid>
        )
      }
    </Grid>
  );
}, R.pipe(
  propTypes({}),
  defaultProps({}),
  memo
));


