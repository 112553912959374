import React, { useCallback, useState, useEffect } from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import { gql, useLazyQuery } from '@apollo/client';
import { Container, Icon, Link, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns/fp';
import { formatDistanceToNowStrict } from 'date-fns';

// aliased
import Progress from 'components/Progress';
import UserAvatar from 'components/UserAvatar';
import CopyToClipboard from 'components/CopyToClipboard';
import NestedData from 'components/NestedData';
import Title from 'components/Title';
import { useParams } from 'lib/hooks/useParams';

// local
import styles from './User.module.scss';


const lookupFields = [
  { field: 'email', name: 'Email', type: 'email' },
  { field: 'nuId', name: 'NU ID' },
  { field: 'upmId', name: 'UPM ID' },
  { field: 'athleteId', name: 'Athlete ID', disabled: true },
];

const LOOKUP_USER = gql`
  query LookupUser($email: EmailAddress, $upmId: String, $nuId: String) {
    user(email: $email, upmId: $upmId, nuId: $nuId) {
      id
      displayName
      email
      upmId
      nuId
      registrationDate
      avatar { url }
    }
  }
`;

// eslint-disable-next-line complexity, max-statements
const UserView = () => {
  const [params, { mergeParams }] = useParams();
  const [result, setResult] = useState(undefined);
  const theme = useTheme();

  const palette = R.path(['palette', 'type'], theme);
  const found = R.path(['data', 'user'], result);
  const error = R.path(['error', 'message'], result);
  const isDone = !!result;

  useEffect(() => {
    // if page loads without a selected field, default it
    if (!params.field) mergeParams({ field: 'email' });
  }, []);
  
  const [runQuery, { loading }] = useLazyQuery(LOOKUP_USER, {
    onCompleted: data => {
      const { user } = data;
      if (user) {
        setResult({ data });
      } else {
        const error = { message: 'User Not Found' };
        setResult({ error });
      }
    },
    onError: error => setResult({ error }),
    notifyOnNetworkStatusChange: true,
  });

  const handleSubmit = useCallback(event => {
    event.preventDefault();
    const variables = { [params.field]: params.value };
    runQuery({ variables });
  }, [runQuery, params]);

  const clearResult = useCallback(_ => setResult(null), [setResult]);

  const handleClear = useCallback(_ => {
    mergeParams({ value: '' });
    clearResult();
  }, []);

  const handleChangeField = useCallback(({ target }) => {
    mergeParams({ field: target.value });
    handleClear();
  }, []);

  const handleValueChange = useCallback(({ target }) => {
    mergeParams({ value: target.value });
    clearResult();
  }, []);

  const field = R.find(R.propEq('field', params.field), lookupFields);
  const registrationDate = R.prop('registrationDate', found);
  const email = R.prop('email', found);

  return (
    <Container className={ cx(styles.userView, styles[palette]) }>
      <Title title={ 'User Lookup' } />
      <Typography variant={ 'h2' } style={{ marginTop: '20px' }}>User Lookup</Typography>
      <form
        className={ styles.lookupForm }
        onSubmit={ handleSubmit }
      >
        <div style={{ display: 'flex' }}>
          <select
            className={ styles.fieldSelector }
            onChange={ handleChangeField }
            title={ 'Select Lookup Field' }
            value={ params.field }
            disabled={ loading }
          >
            {
              lookupFields.map(({ field, name, disabled }) => (
                <option key={ field } value={ field } disabled={ disabled }>
                  { name }
                </option>
              ))
            }
          </select>
          <input
            className={ cx(styles.field) }
            value={ params.value || '' }
            name={ params.field }
            type={ field && field.type }
            disabled={ loading }
            placeholder={ field ? `Enter ${ R.prop('name', field) }` : '' }
            onChange={ handleValueChange }
          />
          <div className={ styles.buttons }>
            <Button
              variant={ 'contained' }
              color={ 'secondary' }
              type={ 'submit' }
              title={ `Lookup ${ R.prop('name', field) }` }
              disabled={ (loading || !params.value || isDone) }
            >
              Submit
            </Button>
            <Button
              type={ 'reset' }
              title={
                isDone
                  ? 'Reset'
                  : `Clear ${ R.prop('name', field) }`
              }
              disabled={ loading || !params.value }
              onClick={ handleClear }
              className={ styles.reset }
            >
              { isDone ? 'Reset' : 'Clear' }
            </Button>
          </div>
        </div>
      </form>

      <div style={{ display: (error ? 'block' : 'none') }} className={ styles.error }>
        { (error || 'Error') }
      </div>

      <Progress
        className={ styles.loading }
        hidden={ !loading }
      >
        { 'Searching...' }
      </Progress>
      <NestedData
        className={ styles.data }
        data={{
          '': R.prop('avatar', found) && (
            <UserAvatar
              className={ styles.avatar }
              user={ found } />
          ),
          Name: R.prop('displayName', found) && (
            <span>
              { R.prop('displayName', found) }
              <CopyToClipboard
                label={ <Icon>{ 'content_copy' }</Icon> }
                text={ R.prop('displayName', found) }
                className={ 'toolbar-button' }
                title={ 'Copy Display Name' } />
            </span>
          ),
          Email: email && (
            <span>
              <Link href={ `mailto:${ email }` }>
                { email }
              </Link>
              <CopyToClipboard
                label={ <Icon>{ 'content_copy' }</Icon> }
                text={ email }
                className={ 'toolbar-button' }
                title={ 'Copy Email' } />
            </span>
          ),
          'UPM ID': R.prop('upmId', found) && (
            <span>
              { R.prop('upmId', found) }
              <CopyToClipboard
                label={ <Icon>{ 'content_copy' }</Icon> }
                text={ R.prop('upmId', found) }
                className={ 'toolbar-button' }
                title={ 'Copy UPM ID' } />
            </span>
          ),
          'NU ID': R.prop('nuId', found) && (
            <span>
              { R.prop('nuId', found) }
              <CopyToClipboard
                label={ <Icon>{ 'content_copy' }</Icon> }
                text={ R.prop('nuId', found) }
                className={ 'toolbar-button' }
                title={ 'Copy NU ID' } />
            </span>
          ),
          'Athlete ID': R.prop('athleteId', found) && (
            <span>
              { R.prop('athleteId', found) }
              <CopyToClipboard
                label={ <Icon>{ 'content_copy' }</Icon> }
                text={ R.prop('athleteId', found) }
                className={ 'toolbar-button' }
                title={ 'Copy Athlete ID' } />
            </span>
          ),
          Registered: registrationDate && (
            <span title={ format('LLL dd yyyy @ h:mm:ss a (xxx)', registrationDate) }>
              {
                R.applyTo(registrationDate, R.pipe(
                  parseISO,
                  formatDistanceToNowStrict,
                  date => `${ date } ago`,
                ))
              }
            </span>
          ),
          Permalink: found && (
            <CopyToClipboard
              label={ <Icon>{ 'content_copy' }</Icon> }
              text={ window.location }
              className={ 'toolbar-button' }
              title={ 'Copy Permalink' } />
          ),
        }}
        hidden={ !found } />
    </Container>
  );
};

export default UserView;
