// modules
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Card } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import { path } from 'ramda';

// aliased
import GraphQLIcon from 'assets/graphql-icon.svg';
import PortfolioIcon from 'assets/portfolio-icon.png';
import SearchUserIcon from 'assets/search-user-icon.png';
import JwtIcon from 'assets/jwt-logo.svg';
import Title from 'components/Title';

// local
import styles from './HomeView.module.scss';


const HomeView = () => {
  const theme = useTheme();

  const palette = path(['palette', 'type'], theme);

  return (
    <Container className={ cx(styles.container, styles[palette]) }>
      <Title title={ 'Home' } />

      <div className={ styles.container }>
        <Card component={ Link } to={ '/auth' } className={ styles.card } title={ 'View Auth Playground' }>
          <div className={ styles.wrapper }>
            <img src={ JwtIcon } style={{ height: '100px', padding: '20px' }} />
          </div>
          { 'Auth Playground' }
        </Card>
        <Card component={ Link } to={ '/user/lookup' } className={ styles.card } title={ 'View User Lookup' }>
          <div className={ styles.wrapper }>
            <img src={ SearchUserIcon } style={{ height: '100px', padding: '10px' }} />
          </div>
          { 'User Lookup' }
        </Card>
        <Card component={ Link } to={ '/graphql' } className={ styles.card } title={ 'View GraphQL Playground' }>
          <div className={ styles.wrapper }>
            <img src={ GraphQLIcon } style={{ height: '100px' }} />
          </div>
          { 'GraphQL Playground' }
        </Card>
        <Card component={ Link } to={ '/bets' } className={ styles.card } title={ 'View VL Portfolio' }>
          <div className={ styles.wrapper }>
            <img src={ PortfolioIcon } style={{ height: '80px', margin: '0 0 20px 0' }} />
          </div>
          { 'Project Portfolio' }
        </Card>
      </div>
    </Container>
  );
};

export default HomeView;
