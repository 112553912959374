/* eslint-disable react/no-multi-comp */
// modules
import React, { memo, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { path } from 'ramda';

// aliased
// Views
import GraphQLView from 'views/GraphQLView';
import DevView from 'views/DevView';
import UserView from 'views/UserView';
import AuthView from 'views/AuthView';
// import PipelineView from 'views/PipelineView';
import HomeView from 'views/HomeView';
import BetsView from 'views/BetsView';
import BetView from 'views/BetView';

// Components
import Progress from 'components/Progress';
// import Error from 'components/Error';
import NotFound from 'components/NotFound';
import Layout from 'components/Layout';
import Logout from 'components/Logout';
// import User from 'components/User';
import Team from 'components/Team';

// lib
import { WHOAMI } from 'lib/graphql/query';

// const RedirectToBets = _ => (<Redirect to={{ pathname: '/bets' }} />);

const RedirectToMe = _ => {
  const { data, loading } = useQuery(WHOAMI);

  if (loading) return (<Progress>{ 'Loading your identity' }</Progress>);
  return (<Redirect to={{ pathname: `/team/${ path(['whoami', 'id'], data) }` }} />);
};

export default memo(() => {
  const { authState } = useOktaAuth();
  const { isAuthenticated } = authState;

  const [getCurrentUser, { data }] = useLazyQuery(WHOAMI);
  const user = path(['whoami'], data);

  useEffect(() => {
    // only if we are currently authenticated should we attempt to get the current user
    if (authState.isAuthenticated) getCurrentUser();
  }, [authState, getCurrentUser]);

  if (!isAuthenticated) return <Progress />;

  return (
    <Layout user={ user }>
      <Switch>
        <Route path='/' exact={ true } component={ HomeView } />
        <Route path='/bets' exact={ true } component={ BetsView } />
        <Route path="/bet/:slug" exact={ false } component={ BetView } />

        <Route path='/user/lookup' exact={ false } component={ UserView } />
        <Route path='/team' exact={ false } component={ Team } />
        <Route path='/graphql' exact={ true } component={ GraphQLView } />

        <Route path='/dev' exact={ true } component={ DevView } />
        <Route path='/auth' exact={ true } component={ AuthView } />
        <Route path='/auth/:provider' exact={ true } component={ AuthView } />
        <Route path='/me' exact={ true } component={ RedirectToMe } />

        <Route path='/logout' exact={ true } component={ Logout } />
        <Route component={ NotFound } />
      </Switch>
    </Layout>
  );
});
