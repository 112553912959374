import { useCallback } from 'react';
import * as R from 'ramda';
import { useHistory, useLocation } from 'react-router-dom';
import {
  parse as parseQs,
  serialize as serializeQs,
} from '@vl/js-lib/browser/url/querystring';


const readQuery = R.pipe(R.path(['location', 'search']), parseQs);

export const useParams = ({ defaults } = {}) => {
  const history = useHistory();
  const location = useLocation();
  const params = readQuery(history);

  const setParams = useCallback(params => {
    history.replace(`${ location.pathname }?${ serializeQs(params) }`);
  }, [history, location]);

  const mergeParams = useCallback(params => {
    setParams({ ...readQuery(history), ...params });
  }, [setParams, history]);

  const clearParams = useCallback(params => setParams({}), []);
  return [params, {
    setParams,
    mergeParams,
    clearParams,
  }];
};
