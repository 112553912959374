import React, { memo, useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { applyTo, pipe, head } from 'ramda';
import { Avatar } from '@material-ui/core';
import cx from 'classnames';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import { parseUserEmail } from 'lib/string';

import styles from './UserAvatar.module.scss';

// eslint-disable-next-line max-statements
export default applyTo(({ className, user, size }) => {
  const [isAvatarLoaded, setAvatarLoaded] = useState(false);
  if (!user) return null;

  const names = R.applyTo(user, R.cond([
    [user => (user.firstName && user.lastName), user => [
      user.firstName,
      user.lastName,
    ]],
    [R.prop('displayName'), user => user.displayName.split(' ')],
    [R.T, user => parseUserEmail(user.email)],
  ]));

  const name = names.join(' ');
  const initials = names.map(head).join(' ');
  
  return (
    <div className={ styles.container }>
      <Avatar
        className={ cx(className, styles.avatar, {
          [styles.fadeIn]: isAvatarLoaded,
        }) }
        alt={ name }
        title={ name }
        src={ user?.avatar?.url }
        size={ +size }
        imgProps={{
          onLoad: () => setAvatarLoaded(true),
        }}
      >
        <p>{ initials }</p>
      </Avatar>
      <Avatar
        className={ cx(className, styles.loader, {
          [styles.fadeOut]: isAvatarLoaded,
        }) }
        alt={ name }
        title={ name }
        size={ +size }
      >
        <></>
      </Avatar>
    </div>
  );
}, pipe(
  propTypes({
    className: PropTypes.string,
    size: PropTypes.number,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  defaultProps({
    size: 50,
  }),
  memo,
));
